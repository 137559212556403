import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import "@angular/localize/init";
import { environment } from "./environment";

const customLoader = `<style>
body {
  display: flex; /* Make the body a flex container */
  justify-content: center; /* Horizontally center elements */
  align-items: center; /* Vertically center elements */
  height: 100vh; /* Set body height to 100% viewport height (optional) */
}
#footer {
  display: none;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
<div class="loader"></div>`;

const routes = constructRoutes(microfrontendLayout, {
  loaders: {
    customLoader,
  },
  props: {}
});
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    loadAppCSS(name);
    return System.import(name);
  },
});
const loadAppCSS = (appName) => {
  let links = [];
  removeCss();
  if (appName == 'platform') {
    links = ["/styles/bootstrap.css", "/styles/all.css", "/styles/toastr.css", "/styles/mydigital.css", "/styles/external.css", "/styles/dev.css"];
  } else if (appName == 'bureau') {
    links = ["/mdp-css/bootstrap.css","/mdp-css/mydigitalpayroll.css", "/mdp-css/payroll-dev.css", "/styles/toastr.css"];
  }
  for (let i = 0; i < links.length; i++) {
    addCss(links[i]);
  }
};
const addCss = (url) => {
  const link = document.createElement('link');
  link.href = environment.assetBaseUrl + url;
  link.rel = 'stylesheet';
  const bootstrapjs = document.createElement('script');
  bootstrapjs.src = "/js/bootstrap.min.js";
  document.head.appendChild(bootstrapjs);
  document.head.appendChild(link);
};
const removeCss = () => {
  let linkelement = document.querySelectorAll('link[rel="stylesheet"]');
  if(linkelement){
    linkelement.forEach(link =>link.remove());
  }
}
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();